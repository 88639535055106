<template>
  <!-- Mobile Match Selector -->
  <div class="matchList-tab has-space" v-if="isMobile">
    <button
      v-for="(o, idx) in matchCompare"
      :key="idx"
      @click="$emit('selectComparison', o.name)"
      :class="{ 'tab-selected': isCompare === o.name }"
    >
      {{ $t(o.name) }}
    </button>
  </div>
  <section class="matchList-container">
    <div class="matchList-content-header" v-if="!isMobile">
      <div class="matchList-content_title">
        {{ $t("HANDICAP_ODDS") }} {{ $t("TABLE") }}
      </div>
      <div class="matchList-header_wrapper">
        <div
          class="matchList-checkbox_wrapper"
          v-for="o in matchCompare"
          :key="o.name"
        >
          <div class="display-flex-center">
            <div
              class="matchList-checkbox_box"
              :class="{ selected: isCompare === o.name }"
              @click="$emit('selectComparison', o.name)"
            ></div>
            <span>{{ $t(o.name) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="matchList-content_wrapper">
      <table class="matchList-content_table table-full" v-if="isMobile">
        <thead>
          <tr>
            <th class="matchList-content_table-title" :colspan="10">
              {{ $t(isCompare) }}
            </th>
          </tr>
          <tr class="matchList-content_table-subtitle">
            <th
              v-for="label in selectorLabel"
              :key="label.name"
              class="matchList-space-half matchList-content_separator"
              :colspan="5"
            >
              <button
                @click="selectMatchTime(label)"
                :class="{ active: label.value === isMatchTime.value }"
              >
                {{ $t(label.name) }}
              </button>
            </th>
          </tr>
          <tr>
            <th :rowspan="headerLabel[0].row" :colspan="2">
              {{ $t(headerLabel[0].name) }}
            </th>
            <th :rowspan="headerLabel[1].row" :colspan="2">
              {{ $t(headerLabel[1].name) }}
            </th>
            <th :colspan="6">{{ $t(isMatchTime.name) }}</th>
          </tr>
          <tr>
            <th :colspan="6">
              {{ `${$t("HANDICAP")} - ${$t("LIVE")}` }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="c in result?.companyOddsList" :key="c.companyId">
            <tr v-for="(o, i) in c.oddsList" :key="i">
              <td :colspan="2" :rowspan="c.oddsList.length" v-if="i === 0">
                {{ $t(companyName(c.companyId)) }}
              </td>
              <td :colspan="2">
                {{ `${$t("HANDICAP") + " " + o.handicapNumber}` }}
              </td>
              <td
                :colspan="2"
                v-for="(d, i) in o[isMatchTime.value]"
                :key="i"
                class="no-border"
              >
                {{
                  d !== null
                    ? i === "odds2"
                      ? d < 0
                        ? "-" + $t(handicapLabel(d.toString().split("-")[1]))
                        : $t(handicapLabel(d.toString()))
                      : d
                    : "-"
                }}
              </td>
            </tr>
          </template>
          <template v-if="result.companyOddsList.length === 0">
            <tr>
              <td :colspan="10">{{ $t("NO_DATA") }}</td>
            </tr>
          </template>
          <tr class="matchList-highlighted">
            <td colspan="4">{{ $t("MAX") }}</td>
            <td
              colspan="2"
              v-for="(o, i) in result[
                `large${
                  isMatchTime.value === 'initOdds' ? 'InitOdds' : 'FinalOdds'
                }`
              ]"
              :key="o"
              class="no-border"
            >
              {{
                o !== null
                  ? i === "odds2"
                    ? o < 0
                      ? "-" + $t(handicapLabel(o.toString().split("-")[1]))
                      : $t(handicapLabel(o.toString()))
                    : o
                  : "-"
              }}
            </td>
          </tr>
          <tr class="matchList-highlighted">
            <td colspan="4">{{ $t("MINIMUM_VALUE") }}</td>
            <td
              colspan="2"
              v-for="(o, i) in result[
                `small${
                  isMatchTime.value === 'initOdds' ? 'InitOdds' : 'FinalOdds'
                }`
              ]"
              :key="o"
              class="no-border"
            >
              {{
                o !== null
                  ? i === "odds2"
                    ? o < 0
                      ? "-" + $t(handicapLabel(o.toString().split("-")[1]))
                      : $t(handicapLabel(o.toString()))
                    : o
                  : "-"
              }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td :colspan="10"></td>
          </tr>
        </tfoot>
      </table>
      <table class="matchList-content_table table-full" v-else>
        <thead>
          <tr>
            <th
              v-for="l in headerLabel"
              :key="l.name"
              :colspan="l.col"
              :rowspan="l.row"
            >
              {{ $t(l.name) }}
            </th>
          </tr>
          <tr>
            <th v-for="(l, i) in childLabel" :key="i">
              {{ $t(l) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="c in result?.companyOddsList" :key="c.companyId">
            <template v-for="(o, i) in c.oddsList" :key="i">
              <tr v-if="i === 0">
                <td>{{ i === 0 ? $t(companyName(c.companyId)) : null }}</td>
                <td v-if="c.oddsList.length > 1 && i === 0">
                  <div
                    class="matchList-expand"
                    @click="expandHandler(c.companyId)"
                  >
                    <img
                      :src="
                        isCompany.includes(c.companyId)
                          ? require('../../../../static/images/icons/rectangle.png')
                          : require('../../../../static/images/icons/add.png')
                      "
                    />
                  </div>
                </td>
                <td v-else>
                  {{ `${$t("HANDICAP") + " " + o.handicapNumber}` }}
                </td>
                <td v-for="(d, i) in o.initOdds" :key="d">
                  {{
                    d !== null
                      ? i === "odds2"
                        ? d < 0
                          ? "-" + $t(handicapLabel(d.toString().split("-")[1]))
                          : $t(handicapLabel(d.toString()))
                        : d
                      : "-"
                  }}
                </td>
                <td v-for="(d, i) in o.finalOdds" :key="d">
                  {{
                    d !== null
                      ? i === "odds2"
                        ? d < 0
                          ? "-" + $t(handicapLabel(d.toString().split("-")[1]))
                          : $t(handicapLabel(d.toString()))
                        : d
                      : "-"
                  }}
                </td>
              </tr>
              <tr v-else-if="isCompany.includes(c.companyId)">
                <td></td>
                <td>
                  {{ `${$t("HANDICAP") + " " + o.handicapNumber}` }}
                </td>
                <td v-for="(d, i) in o.initOdds" :key="d">
                  {{
                    d !== null
                      ? i === "odds2"
                        ? d < 0
                          ? "-" + $t(handicapLabel(d.toString().split("-")[1]))
                          : $t(handicapLabel(d.toString()))
                        : d
                      : "-"
                  }}
                </td>
                <td v-for="(d, i) in o.finalOdds" :key="d">
                  {{
                    d !== null
                      ? i === "odds2"
                        ? d < 0
                          ? "-" + $t(handicapLabel(d.toString().split("-")[1]))
                          : $t(handicapLabel(d.toString()))
                        : d
                      : "-"
                  }}
                </td>
              </tr>
            </template>
          </template>
          <template v-if="result.companyOddsList.length === 0">
            <tr>
              <td :colspan="8">{{ $t("NO_DATA") }}</td>
            </tr>
          </template>

          <tr class="matchList-highlighted">
            <td colspan="2">{{ $t("MAX") }}</td>
            <td v-for="(o, i) in result.largeInitOdds" :key="o">
              {{
                o !== null
                  ? i === "odds2"
                    ? o < 0
                      ? "-" + $t(handicapLabel(o.toString().split("-")[1]))
                      : $t(handicapLabel(o.toString()))
                    : o
                  : "-"
              }}
            </td>
            <td v-for="(o, i) in result.largeFinalOdds" :key="o">
              {{
                o !== null
                  ? i === "odds2"
                    ? o < 0
                      ? "-" + $t(handicapLabel(o.toString().split("-")[1]))
                      : $t(handicapLabel(o.toString()))
                    : o
                  : "-"
              }}
            </td>
          </tr>
          <tr class="matchList-highlighted">
            <td colspan="2">{{ $t("MINIMUM_VALUE") }}</td>
            <td v-for="(o, i) in result.smallInitOdds" :key="o">
              {{
                o !== null
                  ? i === "odds2"
                    ? o < 0
                      ? "-" + $t(handicapLabel(o.toString().split("-")[1]))
                      : $t(handicapLabel(o.toString()))
                    : o
                  : "-"
              }}
            </td>
            <td v-for="(o, i) in result.smallFinalOdds" :key="o">
              {{
                o !== null
                  ? i === "odds2"
                    ? o < 0
                      ? "-" + $t(handicapLabel(o.toString().split("-")[1]))
                      : $t(handicapLabel(o.toString()))
                    : o
                  : "-"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["isCompare", "matchCompare", "result"],
  data() {
    return {
      headerLabel: [
        { name: "COMPANY", col: 1, row: 2 },
        { name: "MULTIPLE_HANDICAP", col: 1, row: 2 },
        { name: "START", col: 3, row: 1 },
        { name: "END", col: 3, row: 1 },
      ],
      selectorLabel: [
        { name: "START", value: "initOdds" },
        { name: "END", value: "finalOdds" },
      ],
      childLabel: ["HOME", "HANDICAP", "AWAY", "HOME", "HANDICAP", "AWAY"],
      isMatchTime: { name: "START", value: "initOdds" },
      isCompany: [],
    };
  },
  mounted() {},
  computed: { ...mapGetters(["isMobile", "currentCountryObj"]) },
  methods: {
    expandHandler(data) {
      if (this.isCompany.includes(data)) {
        this.isCompany = this.isCompany.filter((o) => {
          return o !== data;
        });
      } else this.isCompany.push(data);
    },
    selectMatchTime(data) {
      this.isMatchTime = data;
    },
    handicapLabel(id) {
      switch (id) {
        case "0":
          return "ODDS0";
        case "0.25":
          return "ODDS0_25";
        case "0.5":
          return "ODDS0_5";
        case "0.75":
          return "ODDS0_75";
        case "1":
          return "ODDS1";
        case "1.25":
          return "ODDS1_25";
        case "1.5":
          return "ODDS1_5";
        case "1.75":
          return "ODDS1_75";
        case "2":
          return "ODDS2";
        case "2.25":
          return "ODDS2_25";
        case "2.5":
          return "ODDS2_5";
        case "2.75":
          return "ODDS2_75";
        case "3":
          return "ODDS3";
        case "3.25":
          return "ODDS3_25";
        case "3.5":
          return "ODDS3_5";
        case "3.75":
          return "ODDS3_75";
        case "4":
          return "ODDS4";
        default:
          return "-";
      }
    },
    companyName(el) {
      switch (el) {
        case 1001:
          return this.currentCountryObj?.countryCode === "TH" ? "SBOBET" : "IBCbet";
        case 1:
          return "MACAUSLOT";
        case 3:
          return "SINGBET";
        case 4:
          return "LADBROKES";
        case 7:
          return "SNAI";
        case 8:
          return "BET365";
        case 9:
          return "WILLIAMHILL";
        case 12:
          return "YSB";
        case 14:
          return "BETVICTOR";
        case 17:
          return "MANSION88";
        case 19:
          return "INTERWETTEN";
        case 22:
          return "10BET";
        case 23:
          return "188BET";
        case 24:
          return "12BET";
        case 31:
          return "SBOBET";
        case 35:
          return "WANBET";
        case 42:
          return "18BET";
        case 45:
          return "MANBETX";
        case 47:
          return "PINNACLE";
        default:
          return "-";
      }
    },
  },
};
</script>

<style scoped>
.matchList-space-half {
  width: 50% !important;
}
.matchList-content_table-subtitle th:nth-of-type(1) {
  border-right: 0;
}
.matchList-content_table-subtitle th:nth-of-type(2) {
  border-left: 0;
}
.matchList-content_table td {
  border: 0.5px solid #4b616c;
  padding: 0.375rem 0;
}
.matchList-highlighted td {
  padding: 0.75rem 0;
}
@media (max-width: 768px) {
  .no-border {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .matchList-content_table td,
  .matchList-content_table th {
    border: 0.75px solid #4b616c;
  }
  .matchList-content_table {
    table-layout: fixed;
  }
}
</style>
